import React from 'react'

import DataTable from 'components/data-table'

export default function Stores() {
  
  return (
    <DataTable
      newButton="Store"
      columns={['Name']}
      dataUrl="/admin/stores"
      idName="storeId"
      dataNames={['storeName']}
      formLink="stores"
    />
  )
}
