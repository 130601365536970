import React, { useEffect, useState, useContext, Fragment } from 'react'
import { 
  Button, 
  IconButton,
  ListSubheader, 
  ListItem, 
  ListItemSecondaryAction,
  Typography, 
  Collapse,
  Divider
 } from '@material-ui/core'
import { 
  DragIndicator,  
   ExpandLess,
  ExpandMore, 
} from '@material-ui/icons'

import { Link } from 'react-router-dom'

import axios from 'plugins/axios'

import DeleteConfirmation from 'components/delete-confirmation'
import AppContext from 'app-context'
import PaperContainer from 'components/paper-container';
import CategoryProducts from 'components/products/category-products';
import CreateButton from 'components/small-components/create-button';
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { makeStyles } from '@material-ui/core/styles';

import camelCaseToWords from "lib/camelCaseToWords";


export default function Products() {
  const { showFeedback } = useContext(AppContext)
  const [categories, setCategories] = useState([]);
  const [categoriesOrder, setCategoriesOrder] = useState([]);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false)
  const [preparationLists, setPreparationLists] = useState([]);
  const [activePreparationList, setActivePreparationList] = useState(0)
  const [productToDelete, setProductToDelete] = useState(null)
  const [productToDeleteName, setProductToDeleteName] = useState("")
  
  const [activeCategories, setActiveCategories] = useState([])

  const useStyles = makeStyles(theme => ({
    activeProduct: {
      background: 'rgba(0, 0, 0, .05) !important'
    },
    expanded: {
      padding: theme.spacing(1, 2)
    },
    expandedActions: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  }));
  const classes = useStyles();

  async function loadData(bool) {
    try {
      const productsResponse = await axios.get('/admin/products')
      // console.log(productsResponse)
      setPreparationLists(productsResponse.data);

      const categoriesResponse = await axios.get('/admin/categories')
      setCategories(categoriesResponse.data);
      console.log(categories.map(c => c.categoryId))
      setCategoriesOrder(categoriesResponse.data.map(c => c.categoryId));
      if(bool) {
        setActiveCategories(categoriesResponse.data.map(c => c.categoryId));
      }
    } catch (error) {
      showFeedback(error.message)
      console.log(error)
    }
  }

  useEffect(() => {
    console.log('useEffect')
    loadData(true);

    return () => {
      console.log('useEffect cleanup')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handlePreparationListClick(index) {
    setActivePreparationList(index)
    setActiveCategories(categories.map(c => c.categoryId))
    // console.log(index)
  }

  useEffect(() => {
    async function loadData() {
      try {
        await axios.post(`/admin/categories/sort`,
          categoriesOrder
        )
  
      } catch (error) {
        showFeedback(error.message)
        console.log(error)
      }
    }
    loadData();
  }, [categories])

  async function handleDelete() {
    try {
      await axios.delete(`/admin/products/${productToDelete}`)
      loadData(false)
    } catch (error) {
      showFeedback(error.message)
      console.log(error)
    }

    closeDeleteConfirmation()
    showFeedback(`Product "${productToDeleteName}" deleted`)
  }

  function openDeleteConfirmation(id, name) {
    setProductToDelete(id)
    setProductToDeleteName(name)
    setDeleteConfirmDialogOpen(true)
  }

  function closeDeleteConfirmation() {
    setDeleteConfirmDialogOpen(false)
  }


  function dragChange(categoriesList, destination, source) {
    let tempCategories = categoriesList;
    tempCategories = JSON.parse(JSON.stringify(tempCategories));

    const currentCategory = tempCategories[source.index];
    tempCategories.splice(source.index, 1);
    tempCategories.splice(destination.index, 0, currentCategory);

    return tempCategories;
  }
  const onDragEnd = (result) => {

    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let tempCategories = dragChange(categories, destination, source)
    setCategories(tempCategories);
    preparationLists.forEach(list => {
      list.categories = dragChange(list.categories, destination, source);      
    });
    setCategoriesOrder(tempCategories.map(c => c.categoryId));
  }

  const onDragStart = (result) => {
    console.log("start")
    console.log(categories)

  }

  async function handleListItemClick(id, name) {
    console.log(id, name)
    if (activeCategories.includes(id)) {
      let tempCategories = activeCategories.filter(i => i !== id)
      setActiveCategories(tempCategories);
    } else {
      console.log('Setting Actvie')
      setActiveCategories(activeCategories.concat([id]));
    }
  }

  return (
    <PaperContainer>
      <CreateButton buttonUrl='/products/new' buttonName='New Product'/>

      <div className={"tabList"}>
        {preparationLists.map((pr, prIndex) => (
            <button key={pr.preparationList} onClick={() => handlePreparationListClick(prIndex)} className={`tab ${prIndex === activePreparationList ? "activeTab" : ""}`}>{camelCaseToWords(pr.preparationList)}</button>
        ))}
      </div>

      {preparationLists.length > 0 ?
        <div>

          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId={`dropabale-cat`}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                {preparationLists[activePreparationList].categories.map((c, i) => (

                  <Draggable draggableId={`dragable-${c.categoryId}`} key={c.categoryId} index={i} innerRef={provided.innerRef}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={c.categoryId}>
                          
                        <div key={`category-${c.categoryId}`}  onClick={e => handleListItemClick(c.categoryId, c.categoryName)}>
                          <Divider light />
                          <ListSubheader component="div" className={activeCategories.includes(c.categoryId) ? classes.activeProduct : ''}>

                            <Typography 
                              {...provided.dragHandleProps} variant="h6" className="category">
                              <DragIndicator style={{ position: 'relative', top: '5px' }} />
                              {c.categoryName}
                            </Typography>

                            <ListItemSecondaryAction>
                              <IconButton onClick={e => handleListItemClick(c.categoryId, c.categoryName)}>{activeCategories.includes(c.categoryId) ? <ExpandLess /> : <ExpandMore />}</IconButton>
                            </ListItemSecondaryAction>
                          </ListSubheader>

                          </div>
                          <Collapse 
                            className={`${classes.expanded} ${activeCategories.includes(c.categoryId) && classes.activeProduct}`} 
                            in={activeCategories.includes(c.categoryId)} 
                            timeout="auto" 
                          unmountOnExit >
                            <CategoryProducts
                              category={c}
                              openDeleteConfirmation={openDeleteConfirmation}
                            />
                          </Collapse>

                      </div>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}

                </div>
              )}
            </Droppable>
          </DragDropContext>

      </div>
      : null }

      <DeleteConfirmation
        title="Are you sure?"
        description={`Are you sure you want to delete "${productToDeleteName}"?`}
        open={deleteConfirmDialogOpen}
        onClose={closeDeleteConfirmation}
        onConfirm={handleDelete}
      />
    </PaperContainer>
  )
}
