import axios from 'axios'

window.setAxiosDate = (d) => {
 instance.interceptors.request.use(function (config) {
	 config.headers.common.myDate = d;
    return config;
 });
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  withCredentials: true,
//   headers: {
//     common: {
//       // Submit order for yesterday
//       myDate: window.actAsDate
//     }
//   }
})

export default instance