import React from "react";
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    createButton: {
    margin: theme.spacing(2)
    }
}));

export default function CreateButton(props)
{
    const classes = useStyles()

    return (
        <Button 
        variant="contained" 
        color="primary" 
        className={classes.createButton} 
        component={Link} to={props.buttonUrl}>
            {props.buttonName}
        </Button>
    )
}