import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Container, TextField, Button, Grid, Typography, FormControl, InputLabel, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'plugins/axios'
import PaperContainer from 'components/paper-container';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
}))

export default function Form(props) {
  const classes = useStyles()

  const [values, setValues] = useState(props.defaultValues)
  const [objId, setObjId] = useState(null)

  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  const handleChange = name => event => setValues({...values, [name]: event.target.value})

  const handleSubmit = async event => {
    event.preventDefault()

    let response
    try {
      if (objId) {
        response = await axios.put(`${props.apiUrl}/${objId}`, values)
      } else {
        response = await axios.post(props.apiUrl, values)
      }
      console.log(response)
    } catch (error) {
      console.log(error)
    }
    props.history.push(props.referrer)
  }

  useEffect(() => {
    console.log('useEffect')

    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth)
    }

    const id = props.match.params.id
    if (id) {
      setObjId(id)

      const loadData = async id => {
        try {
          const { data } = await axios.get(`${props.apiUrl}/${id}`)
          setValues(data)
        } catch (error) {
          console.log(error)
        }
      }
      loadData(id)
    }

    return () => {
      console.log('cleanup')
    }
  }, [props.match.params.id, props.apiUrl])

  return (
    <PaperContainer>
      <Container maxWidth="sm" className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Typography component="h2" variant="h6" gutterBottom>{objId ? 'Edit' : 'New'} {props.formName}</Typography>
          <Grid container spacing={3}>
            {props.inputs.map(input => (
              <Grid key={input.key} item xs={12}>
                {input.type === 'select' ? (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel ref={inputLabel} htmlFor={input.text.toLowerCase().replace(' ', '-')}>{input.text}</InputLabel>
                    <Select
                      native
                      value={values[input.key] || ''}
                      onChange={handleChange(input.key)}
                      labelWidth={labelWidth}
                      required={input.required}
                      inputProps={{
                        id: input.text.toLowerCase().replace(' ', '-')
                      }}
                    >
                      <option value=""></option>
                      {input.options.map(option => (
                        <option
                          key={`${input.text.toLowerCase().replace(' ', '-')}_${option.value}`}
                          value={option.value}
                        >{option.text}</option>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    label={input.text}
                    margin="normal"
                    variant="outlined"
                    onChange={handleChange(input.key)}
                    fullWidth
                    value={values[input.key] || ''}
                    required={input.required}
                  />
                )}
              </Grid>
            ))}

            <div className={classes.buttons}>
              <Button variant="outlined" className={classes.button} component={Link} to={props.referrer}>Cancel</Button>
              <Button variant="contained" color="primary" className={classes.button} type="submit">Save</Button>
            </div>
          </Grid>
        </form>
      </Container>
    </PaperContainer>
  )
}
