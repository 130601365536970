import React, { useEffect, useState, useRef, useContext } from "react";

import { Link } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  Switch,
  FormControlLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import axios from "plugins/axios";
import AppContext from "app-context";
import PaperContainer from "components/paper-container";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const apiUrl = "/admin/products";
const defaultValues = {
  productName: "",
  unitType: "",
  preparationList: "",
  categoryId: "",
  price: "",
  available: true,
  dailyOrWeekly: ""
};
const referrer = "/products";

export default function ProductForm(props) {
  const { showFeedback } = useContext(AppContext);
  const classes = useStyles();

  const [values, setValues] = useState(defaultValues);
  const [objId, setObjId] = useState(null);
  const [categories, setCategories] = useState([]);

  const preparationListInputLabel = useRef(null);
  const [preparationListLabelWidth, setPreparationListILabelWidth] = useState(0);
  const categoryInputLabel = useRef(null);
  const [categoryLabelWidth, setCategoryLabelWidth] = useState(0);
  const dailyOrWeeklyInputLabel = useRef(null);
  const [dailyOrWeeklyLabelWidth, setDailyOrWeeklyLabelWidth] = useState(0);

  const handleChange = (name) => (event) => {
    if (event.target.type === "checkbox") {
      setValues({ ...values, [name]: event.target.checked });
      return;
    }
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let [dollars, cents = ""] = `${values.price}`.trim().split(".", 2);
    cents = cents.substr(0, 2).padEnd(2, "0");
    const dbPrice = dollars + cents;

    let response;
    console.log({...values, price: dbPrice})
    try {
      if (objId) {
        response = await axios.put(`${apiUrl}/${objId}`, {
          ...values,
          price: dbPrice,
        });
      } else {
        response = await axios.post(apiUrl, { ...values, price: dbPrice });
      }
      console.log(response);
    } catch (error) {
      showFeedback(error.message);
      console.log(error);
    }
    props.history.push(referrer);
  };

  useEffect(() => {
    console.log("useEffect");

    if (preparationListInputLabel.current) {
      setPreparationListILabelWidth(
        preparationListInputLabel.current.offsetWidth
      );
    }
    if (categoryInputLabel.current) {
      setCategoryLabelWidth(categoryInputLabel.current.offsetWidth);
    }
    if (dailyOrWeeklyInputLabel.current) {
      setDailyOrWeeklyLabelWidth(dailyOrWeeklyInputLabel.current.offsetWidth);
    }

    const loadData = async () => {
      const id = props.match.params.id;
      if (id) {
        setObjId(id);

        try {
          const { data } = await axios.get(`${apiUrl}/${id}`);
          const dbPrice = data.price / 100;
          setValues({ ...data, price: dbPrice || "" });
        } catch (error) {
          showFeedback(error.message);
          console.log(error);
        }
      }

      try {
        const { data } = await axios.get("/admin/categories");
        setCategories(data);
      } catch (error) {
        showFeedback(error.message);
        console.log(error);
      }
    };
    loadData();

    return () => {
      console.log("cleanup");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaperContainer>
      <Container maxWidth="sm" className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Typography component="h2" variant="h6" gutterBottom>
            {objId ? "Edit" : "New"} Product
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                margin="normal"
                variant="outlined"
                onChange={handleChange("productName")}
                fullWidth
                value={values["productName"] || ""}
                required
              />

              <TextField
                label="Unit Type"
                margin="normal"
                variant="outlined"
                onChange={handleChange("unitType")}
                value={values["unitType"]}
                fullWidth
                placeholder="Boxes, Bottles, Buckets etc."
                required
              />

              <TextField
                label="Price"
                margin="normal"
                variant="outlined"
                onChange={handleChange("price")}
                value={values["price"]}
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />

              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel
                  ref={preparationListInputLabel}
                  htmlFor="preparation-list"
                >
                  Preparation List
                </InputLabel>
                <Select
                  value={values["preparationList"]}
                  onChange={handleChange("preparationList")}
                  labelWidth={preparationListLabelWidth}
                  native
                  required
                  inputProps={{ id: "preparation-list" }}
                >
                  <option value=""></option>
                  <option value="dailyGrabAndGo">Daily Grab and Go</option>
                  <option value="dailyVegetablePrep">
                    Daily Vegetable Prep
                  </option>
                  <option value="weeklyInHouseProduct">
                    Weekly In House Product
                  </option>
                  <option value="weeklyOutsideProduct">
                    Weekly Outside Product
                  </option>
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel ref={categoryInputLabel} htmlFor="category">
                  Category
                </InputLabel>
                <Select
                  value={values["categoryId"]}
                  onChange={handleChange("categoryId")}
                  labelWidth={categoryLabelWidth}
                  native
                  required
                  inputProps={{ id: "category" }}
                >
                  <option value=""></option>
                  {categories.map((c) => (
                    <option
                      key={`category_${c.categoryId}`}
                      value={c.categoryId}
                    >
                      {c.categoryName}
                    </option>
                  ))}
                </Select>
              </FormControl>


              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel ref={dailyOrWeeklyInputLabel} htmlFor="dailyOrWeekly">
                  Daily or Weekly
                </InputLabel>
                <Select
                  value={values["dailyOrWeekly"]}
                  onChange={handleChange("dailyOrWeekly")}
                  labelWidth={dailyOrWeeklyLabelWidth}
                  native
                  required
                  inputProps={{ id: "dailyOrWeekly" }}
                >
                  <option value=""></option>
                  <option value="daily">
                    Daily
                  </option>
                  <option value="weekly">
                    Weekly
                  </option>
                </Select>
              </FormControl>


            <FormControlLabel
              control={
                <Switch
                  checked={values["available"]}
                  color="primary"
                  // name="available"
                  onChange={handleChange("available")}
                />
              }
              label="Available"
            />
            
            </Grid>
            <div className={classes.buttons}>
              <Button
                variant="outlined"
                className={classes.button}
                component={Link}
                to={referrer}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
              >
                Save
              </Button>
            </div>
          </Grid>
        </form>
      </Container>
    </PaperContainer>
  );
}
