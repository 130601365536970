import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
  mainPaper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));


export default function PaperContainer({ children }) {
  const classes = useStyles();

  return (
    <Paper className={classes.mainPaper}>
      {children}
    </Paper>
  );
}
