import React, { useContext, useState } from 'react'
import { Route, Link, Switch, Redirect } from 'react-router-dom'
import clsx from 'clsx';
import { Container, Button, Menu, MenuItem, CssBaseline, AppBar, Toolbar, IconButton, Divider, Typography, List, Drawer, ListItem, ListItemText, ListItemIcon, Icon } from '@material-ui/core';
import { AccountCircle, ChevronLeft, Menu as MenuIcon, AddShoppingCart, ShoppingCart, ShoppingBasket, Category, Store, Fastfood
 } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles'

import Stores from 'components/stores'
import StoreForm from 'components/store-form'
import Products from 'components/products'
import ProductForm from 'components/product-form'
import Categories from 'components/categories'
import CategoryForm from 'components/category-form'
import Login from 'components/login'
import PrivateRoute from 'components/private-route'
import NewOrder from 'components/new-order'
import AcceptDelivery from 'components/accept-delivery'
import NotFound from 'components/not-found'
import UnacceptedOrders from 'components/unaccepted-orders';

import AppContext from 'app-context'

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: theme.spacing(3)
  },
  mainPaper: {
    padding: theme.spacing(2)
  }
}));

const ListItemLink = props => <ListItem button component={Link} {...props} />

const links = [
  {
    to: '/products',
    text: 'Products',
    icon: <Fastfood />
  },
  {
    to: '/stores',
    text: 'Stores',
    icon: <Store />
  },
  {
    to: '/categories',
    text: 'Categories',
    icon: <Category />
  }
]

const storeLinks = [
  {
    to: '/order/new',
    text: 'Start a Order',
    icon: <AddShoppingCart />
  },
  {
    to: '/order/all',
    text: 'View Old Orders',
    icon: <ShoppingCart />
  },
  {
    to: '/order/unaccepted',
    text: 'Accept a Delivery',
    icon: <ShoppingBasket />
  }
]

export default function Layout() {
  const { user, logout, handleUpdate } = useContext(AppContext)
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null)
  const handleMenu = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleLogout = () => {
    handleClose()
    logout()
  }

  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => setOpen(true)
  const handleDrawerClose = () => setOpen(false)

  return (
    <div className={classes.root}>
      <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            {user && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              HJ Kitchen App{(user && user.role) === 'Admin' ? ' • Admin' : (user && user.role) === 'StoreManager' ? ' • ' + user.store.storeName : ''}
            </Typography>

            {user ? (
              <>
                <IconButton
                  aria-label="Account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  open={!!anchorEl}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <Typography variant="h5">{user.displayName}</Typography>
                  </MenuItem>
                  <MenuItem>
                    <Typography variant="h6">Role: <Typography component="span" variant="body1">{user.role}</Typography></Typography>
                  </MenuItem>

                  <Divider light />

                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : <Button color="inherit" component={Link} to="/login">Login</Button>}
          </Toolbar>
        </AppBar>

        <Drawer
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
          onClose={handleDrawerClose}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeft />
            </IconButton>
          </div>
          <Divider />
          {user && user.role === 'Admin' && (
            <List>
              {links.map(link => (
                <ListItemLink key={link.text} to={link.to} onClick={handleDrawerClose}>
                  <ListItemIcon>
                    {link.icon}
                  </ListItemIcon>
                  <ListItemText primary={link.text} />
                </ListItemLink>
              ))}
            </List>
          )}
          {user && user.role === 'StoreManager' && (
            <List>
              {storeLinks.map(link => (
                <ListItemLink key={link.text} to={link.to} onClick={handleDrawerClose}>
                  <ListItemIcon>
                    {link.icon}
                  </ListItemIcon>
                  <ListItemText primary={link.text} />
                </ListItemLink>
              ))}
            </List>
          )}
          <Divider />

          <ListItem button onClick={handleUpdate}>
            <ListItemIcon>
              <Icon>update</Icon>
            </ListItemIcon>
            <ListItemText
              primary="Check for updates"
              secondaryTypographyProps={{style: {whiteSpace: 'normal'}}}
            />
          </ListItem>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />

          <Container maxWidth="md" className={classes.mainContainer}>
            <Switch>
              <Route path="/" exact>
                {user
                ? user.role === 'Admin'
                  ? <Redirect to="/products" />
                  :  <Redirect to="/order/new" /> // consider a store manager
                : <Redirect to="/login" />
                }
              </Route>

              <PrivateRoute role="admin" path="/stores" exact component={Stores} />
              <PrivateRoute role="admin" path="/stores/new" component={StoreForm} />
              <PrivateRoute role="admin" path="/stores/:id(\d+)" component={StoreForm} />

              <PrivateRoute role="admin" path="/categories" exact component={Categories} />
              <PrivateRoute role="admin" path="/categories/new" component={CategoryForm} />
              <PrivateRoute role="admin" path="/categories/:id(\d+)" component={CategoryForm} />

              <PrivateRoute role="admin" path="/products" exact component={Products} />
              <PrivateRoute role="admin" path="/products/new" component={ProductForm} />
              <PrivateRoute role="admin" path="/products/:id(\d+)" component={ProductForm} />

              <PrivateRoute role="store" path="/order/new" component={NewOrder} />
              <PrivateRoute role="store" path="/order/unaccepted" component={UnacceptedOrders} />
              <PrivateRoute role="store" path="/order/accept/:deliveryId" component={AcceptDelivery} />

              <Route path="/login" component={Login} />

              <Route component={NotFound} />
            </Switch>
          </Container>
        </main>
      </div>
  );
}
