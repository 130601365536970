import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Container, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Edit, Delete } from '@material-ui/icons'

import axios from 'plugins/axios'
import DeleteConfirmation from 'components/delete-confirmation'
import AppContext from 'app-context'
import PaperContainer from 'components/paper-container';
import CreateButton from 'components/small-components/create-button';

const useStyles = makeStyles(theme => ({
  root: {
    margin: `${theme.spacing(4)}px auto`
  },
  table: {
    marginTop: theme.spacing(2)
  },
  // newButton: {
  //   margin: theme.spacing(2, 0)
  // },
  button : {
    margin: theme.spacing(0, 1)
  }
}))

export default function DataTable(props) {
  const { showFeedback } = useContext(AppContext)
  const [data, setData] = useState([])
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(null)

  const classes = useStyles()

  useEffect(() => {
    console.log('useEffect')
    async function loadData() {
      try {
        const response = await axios.get(props.dataUrl)
        setData(response.data)
      } catch (error) {
        console.log(error)
      }
    }

    loadData()

    return () => {
      console.log('useEffect cleanup')
    }
  }, [props.dataUrl])

  function openDeleteConfirmation(item) {
    setDeleteConfirmDialogOpen(true)

    setActiveItem(item)
  }

  function closeDeleteConfirmation() {
    setDeleteConfirmDialogOpen(false)

    setActiveItem(null)
  }

  async function handleDelete() {
    console.log('Delete', activeItem[props.idName])
    try {
      await axios.delete(`${props.dataUrl}/${activeItem[props.idName]}`)
      const newData = data.filter(el => el[props.idName] !== activeItem[props.idName])
      setData(newData)

    } catch (error) {
      if (error.response && error.response.data) {
        showFeedback(error.response.data)
      } else {
        showFeedback(error.message)
        console.log(error)
      }

    } finally {
      closeDeleteConfirmation()
    }
  }

  return (
    <PaperContainer>
      <Container className={classes.root}>
        {props.newButton && <CreateButton buttonUrl={props.formLink + '/new'} buttonName={`New ${props.newButton}`} />}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {props.columns.map((column, i) => <TableCell key={'column' + i}>{column}</TableCell>)}
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(item => (
              <TableRow key={item[props.idName]}>
                {props.dataNames.map((v, i) => <TableCell key={item[props.idName] + 'c' + i}>{item[v]}</TableCell>)}
                <TableCell align="right">
                  <Link to={`/${props.formLink}/${item[props.idName]}`}>
                    <IconButton className={classes.button} aria-label="edit">
                      <Edit />
                    </IconButton>
                  </Link>

                  <IconButton onClick={e => openDeleteConfirmation(item)} className={classes.button} aria-label="delete">
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <DeleteConfirmation
          title="Are you sure?"
          description="Are you sure you want to delete?"
          open={deleteConfirmDialogOpen}
          onClose={closeDeleteConfirmation}
          onConfirm={handleDelete}
        />
      </Container>
    </PaperContainer>
  )
}
