import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import AppContext from 'app-context'

export default function PrivateRoute({component: Component, role, ...rest}) {

  const { user } = useContext(AppContext)

  if (role && user) {
    if ((role === 'admin' && user.role !== 'Admin') || (role === 'store' && user.role !== 'StoreManager')) {
      return <Redirect to="/" />
    }
  }

  return <Route
    {...rest}
    render={props =>
      user
      ? <Component {...props} {...rest} />
      : <Redirect to={{
          pathname: '/login',
          state: {from: props.location}
        }} />
  } />
}
