import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import LogRocket from 'logrocket'

import Layout from 'components/layout'
import Feedback from 'components/feedback'

import { AppProvider } from 'app-context'
import axios from 'plugins/axios'

const isProd = process.env.REACT_APP_ENV === 'production'

function App(props) {
  const [user, setUser] = useState(null)
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [feedbackActions, setFeedbackActions] = useState([])

  if (isProd) {
    LogRocket.init('0xxjys/hava-java')
  }

  async function login(person) {
    try {
      const response = await axios.post('/account/login', person)
      setUser(response.data)
      localStorage.setItem('user', JSON.stringify(response.data))
      return true
    } catch (error) {
      if (error.response && error.response.status === 401) {
        showFeedback('Wrong credentials. Try Again.')
      }
      console.log(error)
      return false
    }
  }

  function logout() {
    localStorage.setItem('user', '')
    setUser(null)
  }

  function showFeedback(message, actions = []) {
    setFeedbackMessage(message)
    setFeedbackActions(actions)
  }

  function handleCloseFeedback(event, reason) {
		if (reason === 'clickaway') {
			return;
		}

    setFeedbackActions([])
		setFeedbackMessage('')
  }

  const handleUpdate = event => {
    window.postMessage('UPDATE')
    // window.location.reload()
  }

  const showUpdateButton = event => {
    if (event.data === 'UPDATE_AVAILABLE') {
      showFeedback('Update Available', [{
        text: 'Update',
        onClick: handleUpdate
      }])
    }
  }

  useEffect(() => {
    let storedUser = localStorage.getItem('user')
    if (storedUser) {
      storedUser = JSON.parse(storedUser)

      if (isProd) {
        LogRocket.identify(storedUser.userName, {
          name: storedUser.disPlayName,
          email: '',

          // Add your own custom user variables here, ie:
          role: storedUser.role
        })
      }

      setUser(storedUser)
    } else {
      setUser(null)
    }

    window.addEventListener('message', showUpdateButton, false)

    return () => {
      //cleanup
      window.removeEventListener('message', showUpdateButton)
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppProvider value={{
      user,
      login,
      logout,
      showFeedback,
      handleUpdate,
    }}>
      <Router>
        <Layout />

        <Feedback
          message={feedbackMessage}
          open={!!feedbackMessage}
          onClose={handleCloseFeedback}
          actions={feedbackActions}
        />
      </Router>
    </AppProvider>
  )
}

export default App
