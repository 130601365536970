import React from 'react'

import Form from 'components/form';

export default function CategoryForm(props) {

  return (
    <Form
      {...props}
      formName="Category"
      apiUrl="/admin/categories"
      referrer="/categories"
      inputs={[
        {
          key: 'categoryName',
          text: 'Name'
        }
      ]}
      defaultValues={
        {
          categoryName: ''
        }
      }
    />
  )
}
