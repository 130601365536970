import React from 'react'
import { Button } from '@material-ui/core'

import 'styles/loader.css'

export default function SubmitButton({isSubmitting, children, disabled, ...rest}) {

  return (
    <Button disabled={disabled || isSubmitting} {...rest}>
      {isSubmitting && <span className="loader"></span>}
      {children}
    </Button>
  )
}
