import React, { useState, useEffect } from 'react'
import { ListItem, ListItemText, IconButton, ListItemIcon, Collapse, Divider, Container } from '@material-ui/core'
import { Delete, Edit, ExpandLess, ExpandMore, DragIndicator } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';

import axios from 'plugins/axios'

const useStyles = makeStyles(theme => ({
	expanded: {
		padding: theme.spacing(1, 2)
	},
	expandedActions: {
		display: 'flex',
		justifyContent: 'flex-end'
	}
}))

export default function CategoryProducts({
	category: origCategory,
	handleListItemClick,
	openDeleteConfirmation,

}) {
	const classes = useStyles()
	const [category, setCategory] = useState({ products: [] });
	const [productsOrder, setProductsOrder] = useState([]);

	useEffect(() => {
		setCategory(origCategory)
		//setProductsOrder(origCategory.products.map(p => p.productId))
	}, [origCategory]);

	useEffect(() => {
		async function loadData() {
			if (category.categoryId) {
				try {
					const respo = await axios.post(`/admin/products/sort/${category.categoryId}`,
						productsOrder
					)
					console.log(category.categoryId, respo)
				} catch (error) {
					console.log(error)
				}
			}

		}
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productsOrder])

	const onDragStart = (e) => {
		console.log("Event", e);
	}

	const onDragEnd = (result) => {
		let { products } = { ...category }
		products = JSON.parse(JSON.stringify(products));

		const { destination, source } = result;

		if (!destination) {
			return;
		}

		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}
		const currentProduct = products[source.index];
		products.splice(source.index, 1);
		products.splice(destination.index, 0, currentProduct);

		const newCategory = {
			...category,
			products
		};

		setCategory(newCategory);
		setProductsOrder(products.map(p => p.productId))
	}

	return (
		<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
			<Droppable droppableId={`dropabale-${category.categoryId}`} type="PERSON" key={category.categoryId}>
				{(provided) => (
					<Container
						ref={provided.innerRef}
						{...provided.draggableProps}>
						{category.products.map((p, i) => (
							<Draggable draggableId={`dragable-${p.productId}`} key={p.productId} index={i} innerRef={provided.innerRef}
							>
								{(provided) => (
									<div ref={provided.innerRef}
										{...provided.draggableProps}
										key={p.productId}>
										<Divider light />
										<ListItem button disableRipple ContainerComponent="div">
											<ListItemIcon {...provided.dragHandleProps}>
												<DragIndicator />
											</ListItemIcon>
											<ListItemText primary={p.productName + (p.unitType ? (' • ' + p.unitType) : '')} />

											<div className={classes.expandedActions}>
												<Link to={`/products/${p.productId}`}>
													<IconButton className={classes.button} aria-label="edit">
														<Edit />
													</IconButton>
												</Link>

												<IconButton edge="end" aria-label="delete" onClick={() => openDeleteConfirmation(p.productId, p.productName)}>
													<Delete />
												</IconButton>
											</div>

										</ListItem>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</Container>
				)}
			</Droppable>
		</DragDropContext>

	)
}
