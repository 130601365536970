import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Button } from '@material-ui/core'

import PaperContainer from 'components/paper-container';

export default function NotFound() {
  return (
    <PaperContainer>
      <Typography variant="h1" align="center">Not Found</Typography>
      <Typography align="center">
        <Button component={Link} to="/">Go to Home</Button>
      </Typography>
    </PaperContainer>
  )
}
