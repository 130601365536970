import React, { useEffect, useContext, useState } from 'react';
import { Typography, List, ListItem, ListItemText, LinearProgress, ListItemIcon, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';

import axios from 'plugins/axios';
import AppContext from 'app-context';
import PaperContainer from 'components/paper-container';

export default function UnacceptedOrders() {
  const { user } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    async function loadData() {
      setIsLoading(true)
      try {
        const { data } = await axios.get(`/store/accept/store/${user?.store?.storeId}`)
        setOrders(data)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }

    }

    loadData()
    return () => {
      // cleanup
    };
  }, [user])

  return isLoading ? (
    <PaperContainer>
      <LinearProgress />
    </PaperContainer>
  ) : (
    <PaperContainer>
      <Typography variant="h5">Unaccepted Orders ({orders.length})</Typography>

      {!!orders.length && (
        <List component="div">
          {orders.map((o) => (
            <ListItem
              key={`${o.deliveryId}`}
              button
              divider
              tabIndex="-1"
              component={Link}
              to={`/order/accept/${o.deliveryId}`}
            >
              <ListItemText
                primary={`Delivery #${
                  o.deliveryId
                } • Date expected: ${new Intl.DateTimeFormat("en-US").format(
                  new Date(o.dateToArrive)
                )}`}
              />

              <ListItemIcon>
                <Icon>chevron_right</Icon>
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      )}
    </PaperContainer>
  );
}
