import React, { useEffect, useState, useContext } from 'react'
import {
  Button, Container, Divider,
  ListItem, ListItemText, IconButton, ListItemIcon
} from '@material-ui/core'
import { Delete, Edit, DragIndicator } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

import { Link } from 'react-router-dom'

import axios from 'plugins/axios'

import DeleteConfirmation from 'components/delete-confirmation'
import AppContext from 'app-context'
import PaperContainer from 'components/paper-container';
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import CreateButton from 'components/small-components/create-button';


const useStyles = makeStyles(theme => ({
  root: {
    margin: `${theme.spacing(4)}px auto`
  },
  table: {
    marginTop: theme.spacing(2)
  },
  newButton: {
    margin: theme.spacing(2, 0)
  },
  button: {
    margin: theme.spacing(0, 1)
  },
  category: {
    fontWeight: 'bold'
  },
  activeProduct: {
    background: 'rgba(0, 0, 0, .05) !important'
  },
  expanded: {
    padding: theme.spacing(1, 2)
  },
  expandedActions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  storesContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  storeCard: {
    minWidth: 250,
    margin: theme.spacing(1)
  },
  qtyInput: {
    marginLeft: 'auto',
    width: 80
  },
  tabList: {
    padding: "16px"
  },
  tab: {
    borderBottom: 0,
    backgroundColor: "#fff"
  },
  activeTab: {
    backgroundColor: "#eee"
  }
}))

export default function Categories() {
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesListOrder, setCategoriesListOrder] = useState([]);
  const { showFeedback } = useContext(AppContext)
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false)
  const [categoryToDelete, setCategoryToDelete] = useState(null)
  const [categoryToDeleteName, setCategoryToDeleteName] = useState("")

  const classes = useStyles()

  async function loadData() {
    try {
      const response = await axios.get('/admin/categories')
      console.log(response.data);
      setCategoriesList(response.data);
    } catch (error) {
      showFeedback(error.message)
      console.log(error.message)
    }
  }

  useEffect(() => {
    console.log('useEffect in categories.js')

    loadData()

    return () => {
      console.log('useEffect cleanup')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function loadSortData() {
      try {
        await axios.post(`/admin/categories/sort`,
          categoriesListOrder
        )
      } catch (error) {
        showFeedback(error.message)
        console.log(error)
      }
    }
    loadSortData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesList])

  const onDragStart = (e) => {
    console.log("Event", e);
  }

  const onDragEnd = (result) => {

    let categories = categoriesList;
    console.log(categories)
    categories = JSON.parse(JSON.stringify(categories));

    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const currentCategory = categories[source.index];
    categories.splice(source.index, 1);
    categories.splice(destination.index, 0, currentCategory);

    setCategoriesList(categories);
    setCategoriesListOrder(categories.map(c => c.categoryId));
  }

  async function handleDelete() {
    try {
      let response = await axios.delete(`/admin/categories/${categoryToDelete}`);
      console.log(response)
      loadData();      
      showFeedback(`Product "${categoryToDeleteName}" deleted`)
    } catch (error) {
      showFeedback(error.message)
      console.log(error)
    }
    closeDeleteConfirmation()
  }

  function openDeleteConfirmation(id, name) {
    setCategoryToDelete(id)
    setCategoryToDeleteName(name)
    setDeleteConfirmDialogOpen(true)
  }

  function closeDeleteConfirmation() {
    setDeleteConfirmDialogOpen(false)
  }

  return (
    <PaperContainer>
      <CreateButton buttonUrl='/categories/new' buttonName='New Category' />

      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId={`dropabale-cat`}>
          {(provided) => (
            <Container
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div >
                {categoriesList.map((c, i) => (
                  <Draggable draggableId={`dragable-${c.categoryId}`} key={c.categoryId} index={i} innerRef={provided.innerRef}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={c.categoryId}>
                        <Divider light />
                        <ListItem button disableRipple ContainerComponent="div">
                          <ListItemIcon {...provided.dragHandleProps}>
                            <DragIndicator />
                          </ListItemIcon>
                          <ListItemText primary={c.categoryName} />

                          <div className={classes.expandedActions}>
                            <Link to={`/categories/${c.categoryId}`}>
                              <IconButton className={classes.button} aria-label="edit">
                                <Edit />
                              </IconButton>
                            </Link>

                            <IconButton edge="end" aria-label="delete" onClick={() => openDeleteConfirmation(c.categoryId, c.categoryName)}>
                              <Delete />
                            </IconButton>
                          </div>
                        </ListItem>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>

              {provided.placeholder}

            </Container>
          )}
        </Droppable>
      </DragDropContext>

      <DeleteConfirmation
        title="Are you sure?"
        description={`Are you sure you want to delete "${categoryToDeleteName}"?`}
        open={deleteConfirmDialogOpen}
        onClose={closeDeleteConfirmation}
        onConfirm={handleDelete}
      />
    </PaperContainer>
  )
}
