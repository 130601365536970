import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'

export default function DeleteConfirmation({title, description, open, onClose, onConfirm}) {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-confirmation-title"
      aria-describedby="delete-confirmation-description"
    >
      <DialogTitle id="delete-confirmation-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-confirmation-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={onConfirm} color="primary">Delete</Button>
      </DialogActions>
    </Dialog>
  )
}