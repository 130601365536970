import React, { useEffect, Fragment, useState } from 'react'
import { Typography, List, ListItem, ListItemText, Collapse, TextField, FormGroup, Button, Divider, LinearProgress, Grid, Icon, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import { useParams } from 'react-router-dom';
import { Prompt } from 'react-router'


import axios from 'plugins/axios'
import SubmitButton from 'components/submit-button'
import PaperContainer from 'components/paper-container';

const useStyles = makeStyles(theme => ({
  product: {
    borderTop: '1px solid #dedede',
    transition: 'box-shadow .5s ease',
    '&:first-child': {
      borderTop: 'unset'
    }
  },
  searchedProduct: {
    boxShadow: 'inset 0px 0px 10px 1px #688abd'
  },
  alignCenter: {
    textAlign: 'center'
  },
  button: {
    cursor: 'pointer'
  },
  notesContainer: {
    marginTop: '18px'
  },
  categoryHeader: {
	  backgroundColor: "rgb(236 236 236)"
  }
}))

const status = {
  missing: 'Missing',
  accepted: 'Accepted',
  modified: 'Modified',
}

const ProductLine = ({
    product,
    activeProduct,
    status,
    onAccept,
    onMissing,
    onQtyInput,
    onQtyClick,
    onComment,
    onListItemClick,
    className,
	searchedClassName,
	displayCategory
	}) => {
		const classes = useStyles();

		return (
			<Fragment>
				<Divider light />
				{displayCategory  &&
					<ListItem className={classes.categoryHeader}>
						<ListItemText primary={product.category.categoryName}/>
					</ListItem>
				} 
				<ListItem
				className={`${className} ${searchedClassName}`}
				button
				dense
				tabIndex="-1"
				onClick={() => onListItemClick(product.id)}
				autoFocus={!!searchedClassName}
				>
				<ListItemText
					primary={product.name}
					secondary={
					<>
						Ordered <span style={{fontWeight: 'bold'}}>{product.orderedQty}</span> {product.unitType}  
						{product.status &&
						<> • Received <span style={{fontWeight: 'bold'}}>{product.receivedQty}</span></>
						}
					</>
					}
				/>

				{!product.status &&
					<Button color="primary" variant="outlined" onClick={onAccept(product.id)}>{product.id === activeProduct ? 'Save' : 'Accept'}</Button>
				}
				</ListItem>

				<Collapse
				in={product.id === activeProduct}
				timeout="auto"
				unmountOnExit
				>
				<FormGroup >
					<Grid container justify="center" alignItems="center">
					<Grid item xs={2} sm={1} style={{textAlign: 'right'}}>
						<IconButton onClick={onQtyClick(product.id, 'down')}>
						<Icon>chevron_left</Icon>
						</IconButton>
					</Grid>

					<Grid item xs={6} sm={4}>
						<TextField
						variant="outlined"
						margin="normal"
						label="Quantity Received"
						type="number"
						value={product.receivedQty || ''}
						onChange={onQtyInput(product.id)}
						fullWidth
						/>
					</Grid>

					<Grid item xs={2} sm={1} style={{textAlign: 'left'}}>
						<IconButton onClick={onQtyClick(product.id, 'up')}>
						<Icon>chevron_right</Icon>
						</IconButton>
					</Grid>
					</Grid>

					{!product.status && product.orderedQty > 0 &&
					<Grid container justify="center">
						<Button color="secondary" variant="text" onClick={onMissing(product.id)}>All are Missing</Button>
					</Grid>
					}

					<TextField
					variant="outlined"
					margin="normal"
					multiline
					rows="3"
					rowsMax="5"
					label="Comments"
					onChange={onComment(product.id)}
					value={product.arrivalNote || ''}
					/>
				</FormGroup>
				</Collapse>
			</Fragment>
		)
}

export default function Accept() {
  const { deliveryId } = useParams();
  const [delivery, setDelivery] = useState({})
  const [products, setProducts] = useState({})
  const [activeProduct, setActiveProduct] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [expandDone, setExpandDone] = useState(false)
  const [expandNotOrdered, setExpandNotOrdered] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [searchedProductId, setSearchedProductId] = useState(-1)
  const [blockNavigation, setBlockNavigation] = useState(false)

  const classes = useStyles()

  const handleListItemClick = id => {
    if (id === activeProduct) {
      setActiveProduct(0)
    } else {
      setActiveProduct(id)
    }
  }

  const handleAccept = id => event => {

    let updatedStatus = null
    if (+products[id].receivedQty === 0) {
      updatedStatus = status.missing
    } else if (products[id].receivedQty !== products[id].orderedQty) {
      updatedStatus = status.modified
    } else {
      updatedStatus = status.accepted
    }

    setProducts({
      ...products,
      [id]: {
        ...products[id],
        status: updatedStatus,
        receivedQty: products[id].receivedQty
      }
    })
  }

  const handleMissing = id => event => {
	setBlockNavigation(true)
    setProducts({
      ...products,
      [id]: {
        ...products[id],
        status: status.missing,
        receivedQty: '0'
      }
    })

    // Close the collapse
    setActiveProduct(0)
  }

  const handleQtyChange = id => event => {
	setBlockNavigation(true)
    let value = event.target.value
    if (value < 0) {
      return
    }

    let updatedStatus = null
    if (products[id].status) {
      if (+value === 0) {
        updatedStatus = status.missing
      } else if (value !== products[id].orderedQty) {
        updatedStatus = status.modified
      } else {
        updatedStatus = status.accepted
      }
    }

    setProducts({
      ...products,
      [id]: {
        ...products[id],
        status: updatedStatus,
        receivedQty: value
      }
    })
  }

  const changeQty = (id, direction) => event => {
	setBlockNavigation(true)
    const currentValue = +products[id].receivedQty
    const newValue = direction === 'up' ? currentValue + 1 : currentValue - 1

      if (newValue < 0) {
        return
      }

    setProducts({
      ...products,
      [id]: {
        ...products[id],
        receivedQty: `${newValue}`
      }
    })
  }

  const handleCommentChange = id => event => {
	setBlockNavigation(true)
    setProducts({
      ...products,
      [id]: {
        ...products[id],
        arrivalNote: event.target.value
      }
    })
  }

  const handleArrivalNoteChange = event => {
	setBlockNavigation(true)
    setDelivery({...delivery, arrivalNote: event.target.value});
  };

  const handleSearchChange = event => {
    const { textContent } = event.target
    // TODO: What if there is multiple products with the same name, e.g. in separate categories
    const p = Object.values(products).find(p => p.name === textContent)
    if (p) {
      setSearchedProductId(p.id)
    }

    // Remove box-shadow class after 3 seconds
    setTimeout(() => setSearchedProductId(-1), 3000)
  }

  const handleSave = async event => {
    event.preventDefault()

    setIsSubmitting(true)

    try {
      const response = await axios.post(`/store/accept/${delivery.deliveryId}`, {
        ...delivery,
        products: [
          ...delivery.products.map((p, i) => ({
            ...p,
            amountArrived: products[p.product.productId].receivedQty,
            arrivalNote: products[p.product.productId].arrivalNote
          }))
        ]
      })
      console.log(response)

      setIsSubmitted(true)

    } catch (error) {
      console.log(error)

    } finally {
	  setBlockNavigation(false)

      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `/store/accept/delivery/${deliveryId}`
        );
        const tempProducts = {};
        data.products.forEach((p) => {
          tempProducts[p.product.productId] = {
            id: p.product.productId,
            name: p.product.productName,
            sortOrderNum: p.product.sortOrderNum,
            unitType: p.product.unitType,
            orderedQty: p.quantity,
            status: null,
			receivedQty: p.quantity,
			category: p.product.category,
          };
        });

        setDelivery(data);
        setProducts(tempProducts);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
    return () => {
      // cleanup
    };
  }, [deliveryId]);


  useEffect(()=> {
	if(blockNavigation){  
		window.onbeforeunload = () => true
	}
	else{
		window.onbeforeunload = () => undefined;
	}
	return () => window.onbeforeunload = () => undefined;
		
  }
  , [blockNavigation])

  return isLoading ? (
	
	   
    <PaperContainer>
      <LinearProgress />
    </PaperContainer>
  ) : isSubmitted || delivery.dateTimeAccepted ? (
	<PaperContainer>
	<div style={{ padding: "18px" }}>
		<Typography align="center" variant="h4">
		Delivery Accepted!
		</Typography>
	</div>
	</PaperContainer>
	
  ) : (
    <div>
	  <Prompt
		when={blockNavigation}
	  	message='You have unsaved changes, are you sure you want to leave?'
	  />
      <PaperContainer>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h5">Accept Delivery</Typography>
            <Typography>Delivery ID: {delivery.deliveryId}</Typography>
          </Grid>

          <Grid item>
            <Autocomplete
              options={Object.values(products)}
              getOptionLabel={(o) => o.name}
              style={{ width: 300, paddingTop: "8px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  variant="outlined"
                  fullWidth
                />
              )}
              onChange={handleSearchChange}
            />
          </Grid>
        </Grid>

        <List component="div">
          {products &&
            Object.values(products)
              .filter((p) => p.status === null && p.orderedQty > 0)
                .sort((a, b) => {
                  if (a.category.sortOrderNum < b.category.sortOrderNum) {
                    return -1
                  }
                  else if (a.category.sortOrderNum > b.category.sortOrderNum) {
                    return 1
                  }
                  else {
                    return a.sortOrderNum < b.sortOrderNum ? -1 : 1
                  }

                }
                )
              .map((p,i,arr) => 
                 (<ProductLine
                  key={p.id}
                  product={p}
                  activeProduct={activeProduct}
                  displayCategory={i === 0 || arr[i-1].category.categoryName !== p.category.categoryName}
                  status={status}
                  onAccept={handleAccept}
                  onMissing={handleMissing}
                  onQtyInput={handleQtyChange}
                  onQtyClick={changeQty}
                  onComment={handleCommentChange}
                  onListItemClick={handleListItemClick}
                  className={classes.product}
                  searchedClassName={
                    searchedProductId === p.id ? classes.searchedProduct : ""
                  }
                />)
				)}

          <Divider />
        </List>

        <Grid container spacing={1} className={classes.notesContainer}>
          <Grid item sm={12} xs={12}>
            <TextField
              label="Delivery Note"
              value={delivery.arrivalNote || ""}
              onChange={handleArrivalNoteChange}
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              rowsMax={5}
            />
          </Grid>
        </Grid>

        <SubmitButton
          style={{ margin: "18px 0" }}
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          onClick={handleSave}
          disabled={
            !!Object.values(products).filter(
              (p) => p.status === null && p.orderedQty > 0
            ).length
          }
          isSubmitting={isSubmitting}
        >
          Complete
        </SubmitButton>
      </PaperContainer>

      <PaperContainer>
        <Grid
          container
          alignItems="center"
          onClick={() => setExpandDone(!expandDone)}
          className={classes.button}
        >
          <Typography variant="h6">
            Accepted (
            {Object.values(products).filter((p) => p.status !== null).length})
          </Typography>

          <IconButton style={{ marginLeft: "auto" }}>
            <Icon>{expandDone ? "expand_more" : "expand_less"}</Icon>
          </IconButton>
        </Grid>
        <Collapse in={expandDone} timeout="auto" unmountOnExit>
          <List component="div">
            {products &&
              Object.values(products)
			    .filter((p) => p.status !== null)
                  .sort((a, b) => {
                    if (a.category.sortOrderNum < b.category.sortOrderNum) {
                      return -1
                    }
                    else if (a.category.sortOrderNum > b.category.sortOrderNum) {
                      return 1
                    }
                    else {
                      return a.sortOrderNum < b.sortOrderNum ? -1 : 1
                    }

                  }
                  )
				.map((p,i,arr) => (
                  <ProductLine
                    key={p.id}
                    product={p}
					activeProduct={activeProduct}
					displayCategory={i === 0 || arr[i-1].category.categoryName !== p.category.categoryName}
                    status={status}
                    onAccept={handleAccept}
                    onMissing={handleMissing}
                    onQtyInput={handleQtyChange}
                    onQtyClick={changeQty}
                    onComment={handleCommentChange}
                    onListItemClick={handleListItemClick}
                  />
                ))}
          </List>
        </Collapse>
      </PaperContainer>

      <PaperContainer>
        <Grid
          container
          alignItems="center"
          onClick={() => setExpandNotOrdered(!expandNotOrdered)}
          className={classes.button}
        >
          <Typography variant="h6">
            Not Ordered (
            {Object.values(products).filter((p) => p.orderedQty === 0  &&  p.status === null).length})
          </Typography>

          <IconButton style={{ marginLeft: "auto" }}>
            <Icon>{expandNotOrdered ? "expand_more" : "expand_less"}</Icon>
          </IconButton>
        </Grid>
        <Collapse in={expandNotOrdered} timeout="auto" unmountOnExit>
          <List component="div">
            {products &&
              Object.values(products)
					.filter((p) => p.orderedQty === 0 &&  p.status === null)
                  .sort((a, b) => {
                    if (a.category.sortOrderNum < b.category.sortOrderNum) {
                      return -1
                    }
                    else if (a.category.sortOrderNum > b.category.sortOrderNum) {
                      return 1
                    }
                    else {
                      return a.sortOrderNum < b.sortOrderNum ? -1 : 1
                    }

                  }
                  )
				.map((p,i,arr) => (
                  <ProductLine
                    key={p.id}
                    product={p}
					activeProduct={activeProduct}
					displayCategory={i === 0 || arr[i-1].category.categoryName !== p.category.categoryName}
                    status={status}
                    onAccept={handleAccept}
                    onMissing={handleMissing}
                    onQtyInput={handleQtyChange}
                    onQtyClick={changeQty}
                    onComment={handleCommentChange}
                    onListItemClick={handleListItemClick}
                  />
                ))}
          </List>
        </Collapse>
      </PaperContainer>
    </div>
  );
}
