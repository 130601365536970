import React from 'react'
import { Snackbar, IconButton, Button } from '@material-ui/core'
import { Close } from '@material-ui/icons'

export default function Feedback({ open, onClose, message, actions = []}) {

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left'
			}}
			open={open}
			autoHideDuration={6000}
			onClose={onClose}
			ContentProps={{'aria-describedby': 'feedback-message'}}
			message={<span id="feedback-message">{message}</span>}
			action={[
				...actions
					.map(action => (
						<Button key={action.text} color="inherit" onClick={action.onClick}>{action.text}</Button>
					)),
					<IconButton
						key="undo"
						aria-label="close"
						color="inherit"
						onClick={onClose}
					>
						<Close />
					</IconButton>
			]}
		></Snackbar>
	)
}
