import React from 'react'

import Form from 'components/form';

export default function StoreForm(props) {

  return (
    <Form
      {...props}
      formName="Store"
      apiUrl="/admin/stores"
      referrer="/stores"
      inputs={[
        {
          key: 'storeName',
          text: 'Name'
        }
      ]}
      defaultValues={
        {
          storeName: ''
        }
      }
    />
  )
}
