import React, { useEffect, useContext, useState, Fragment } from "react";
import {
  List,
  // Link,
  ListSubheader,
  ListItem,
  ListItemText,
  TextField,
  Collapse,
  Button,
  ButtonGroup,
  // FormControlLabel,
  // Tooltip,
  Typography,
  // Switch,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

import axios from "plugins/axios";
import AppContext from "app-context";
import PaperContainer from "components/paper-container";
import { Prompt } from 'react-router'
// import { FormatListBulleted } from "@material-ui/icons";

import camelCaseToWords from "lib/camelCaseToWords";


const useStyles = makeStyles((theme) => ({
  subheader: {
    background: "#f1f1f1",
  },
  product: {
    borderTop: "1px solid #dedede",
    transition: "box-shadow .5s ease",
    "&:first-child": {
      borderTop: "unset",
    },
  },
  searchedProduct: {
    boxShadow: "inset 0px 0px 10px 1px #688abd",
  },
  textField: {
    width: 100,
  },
  replenishWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  omitProduct: {
    textDecoration: "line-through",
  },
  activeProduct: {
    background: "rgba(0, 0, 0, .05) !important",
  },
  expanded: {
    padding: theme.spacing(1, 2),
  },
  resetButton: {
    marginRight: theme.spacing(1),
  },
  orderSummary: {
    margin: theme.spacing(3),
  },
  submitButton: {
    margin: theme.spacing(2, 0),
  },
  completedMessage: {
    padding: theme.spacing(3),
  },
  daysToPrepare: {
    color: "rgb(0 0 0 / 0.32)",
    fontSize: "0.7em",
    marginLeft: "4px",
  },
}));

export default function NewOrder() {
  const { user, showFeedback } = useContext(AppContext);
  const [categories, setCategories] = useState([]);
  // const [preparationLists, setPreparationLists] = useState([]);
  const [activeProduct, setActiveProduct] = useState(0);
  const [orderQty, setOrderQty] = useState({});
  const [productNotes, setProductNotes] = useState({});
  const [omitProduct, setOmitProduct] = useState({});
  const [modifiedProducts, setModifiedProducts] = useState({});
  const [orderNote, setOrderNote] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchedProductId, setSearchedProductId] = useState(-1);
  const [blockNavigation, setBlockNavigation] = useState(false)

  const [chooseDailyOrWeekly, setChooseDailyOrWeekly] = useState(true)
  const [dailyOrWeekly, setDailyOrWeekly] = useState(null)

  const classes = useStyles();

  function handleDailyOrWeekly(dw) {
    return function(event) {
      setDailyOrWeekly(dw);
      setChooseDailyOrWeekly(false);
    }
  }
  function handleListItemClick(id) {
    return function (event) {
      if (event.target.tagName === "INPUT") return;

      if (id === activeProduct) {
        setActiveProduct(0);
      } else {
        setActiveProduct(id);
      }
    };
  }

  function handleOrderChange(id) {

    return function (event) {
      setBlockNavigation(true) 

      const value = +event.target.value;

      if (value < 0) {
        event.target.value = 0;
        // Disallow negative values
        return;
      }

      setOrderQty({ ...orderQty, [id]: `${value}` });
      setModifiedProducts({ ...modifiedProducts, [id]: true });
    };
  }

  function handleProductNoteChange(id) {

    return function (event) {
      setBlockNavigation(true)

      setProductNotes({ ...productNotes, [id]: event.target.value });
    };
  }

  function handleOrderNoteChange(event) {
	  setBlockNavigation(true)

    setOrderNote(event.target.value);
  }

  async function handleSave(event) {
    const products = [];
    for (let [key, value] of Object.entries(orderQty)) {
      if (value > 0) {
        products.push({
          productId: key,
          quantity: value,
          orderNote: productNotes[key],
        });
      }
    }
    if (products.length == 0) {
      showFeedback("Cannot submit empty order.");
      return;
    }

    const order = {
      storeId: user.store && user.store.storeId,
      orderNote,
      products,
    };
    // console.log(order)

    try {
      setSubmitting(true);
      const response = await axios.post("/store/request", order);
      console.log(response);
      setSubmitting(false);
      setIsSubmitted(true);
      setBlockNavigation(false)

    } catch (error) {
      showFeedback(error.message);
      console.log(error);
    }
  }

  function handleNewOrder(event) {
    setIsSubmitted(false);
    setChooseDailyOrWeekly(true);
  }

  const handleSearchChange = (event) => {
    const { textContent } = event.target;
    // TODO: What if there is multiple products with the same name, e.g. in separate categories
    const p = products.find((p) => p.name === textContent);
    if (p) {
      setSearchedProductId(p.id);
    }

    // Remove box-shadow class after 3 seconds
    setTimeout(() => setSearchedProductId(-1), 3000);
  };

  useEffect(() => {
    if(dailyOrWeekly !== null) {

      async function loadData() {
        try {

          const { data } = await axios.get(
            `/store/request/products/${dailyOrWeekly}`
            // `/store/request/products/${user.store && user.store.storeId}`
          );
          setCategories(data);
          // setPreparationLists(data);

          /* key: productId, will hold data for all products */
          const current = {};
          const omit = {};
          const notes = {};
          const orderQty = {};
          const productList = [];
          /* 
          data is nested as:
          preparationList.categories.products
          */
          data.forEach((c) =>
            // pr.categories.forEach((c) => 
              c.products.forEach((p) => 
              {
                current[p.productId] = "";
                omit[p.productId] = false;
                notes[p.productId] = "";
                orderQty[p.productId] = 0;
                productList.push({
                  id: p.productId,
                  name: p.productName,
                  category: c.categoryName,
                  // grouping: `${camelCaseToWords(pr.preparationList)} - ${c.categoryName}`
                });
              })
            // )
          );
          setProducts(productList);
          setOrderQty(orderQty);
          setOmitProduct(omit);
          setProductNotes(notes);

          setIsLoaded(true);
        } catch (error) {
          showFeedback(error.message);
          console.log(error);
        }
      }

      if (user) {
        loadData();
      }

      return () => {
        // cleanup
      };

    }
  }, [user, dailyOrWeekly]);

  useEffect(()=> {
	if(blockNavigation){  
		window.onbeforeunload = () => true
	}
	else{
		window.onbeforeunload = () => undefined;
	}
	return () => window.onbeforeunload = () => undefined;
		
  }
  , [blockNavigation])


  return chooseDailyOrWeekly ? (
    <PaperContainer>
        <Typography align="center" variant="h4">
          Please choose your order type.
        </Typography>

      <Typography align="center" variant="h4">
        <ButtonGroup variant="contained" color="primary" aria-label="outlined primary button group">
          <Button onClick={handleDailyOrWeekly("daily")}>Daily Products</Button>
          <Button onClick={handleDailyOrWeekly("weekly")}>Weekly Products</Button>
        </ButtonGroup>
      </Typography>
    </PaperContainer>
  ) : !isLoaded ? (
    <PaperContainer>
      <LinearProgress />
    </PaperContainer>
  ) : submitting ? ( 
    <PaperContainer>
        <LinearProgress />
    </PaperContainer>
  ) : isSubmitted ? ( 
    <PaperContainer>
      <div className={classes.completedMessage}>
        <Typography align="center" variant="h4">
          Order Submitted!
        </Typography>
        <Typography align="center">
          <Button
            className={classes.submitButton}
            color="primary"
            variant="contained"
            onClick={handleNewOrder}
          >
            Create A New Order
          </Button>
        </Typography>
    </div>
    </PaperContainer>
  ) : (
    <PaperContainer>
	  <Prompt
		when={blockNavigation}
		message='You have unsaved changes, are you sure you want to leave?'
	  />
      <Autocomplete
      
        options={products}
        groupBy={(o) => o.grouping}
        getOptionLabel={(o) => o.name}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Search" variant="outlined" fullWidth />
        )}
        onChange={handleSearchChange}
      />

      <List component="div">           

        {categories
          .filter((c) => c.products.length)
          .map((c) => (
            <div key={`category-${c.categoryId}`}>
              <ListSubheader component="div" className={classes.subheader}>
                {c.categoryName}
              </ListSubheader>
              {c.products
                
                .map((p) => (
                  <Fragment key={`product-${p.productId}`}>
                    <ListItem
                      className={`${classes.product} ${
                        p.productId === activeProduct
                          ? classes.activeProduct
                          : ""
                      } ${
                        p.productId === searchedProductId
                          ? classes.searchedProduct
                          : ""
                      }`}
                      button
                      dense
                      onClick={handleListItemClick(p.productId)}
                      tabIndex="-1"
                      autoFocus={p.productId === searchedProductId}
                    >
                      <ListItemText
                        primary={
                          <>
                            {p.productName}{" "}
                          </>
                        }
                        primaryTypographyProps={{
                          className: omitProduct[p.productId]
                            ? classes.omitProduct
                            : "",
                        }}
                      />
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        type="number"
                        label="Please Send"
                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        value={orderQty[p.productId]}
                        onChange={handleOrderChange(p.productId)}
                        disabled={omitProduct[p.productId]}
                      />
                    </ListItem>
                    <Collapse
                      in={p.productId === activeProduct}
                      className={`${classes.expanded} ${
                        p.productId === activeProduct && classes.activeProduct
                      }`}
                      timeout="auto"
                      unmountOnExit
                    >
                      <TextField
                        label="Note"
                        multiline
                        margin="normal"
                        variant="outlined"
                        rows="2"
                        rowsMax="4"
                        fullWidth
                        value={productNotes[p.productId]}
                        onChange={handleProductNoteChange(p.productId)}
                      />
                    </Collapse>
                  </Fragment>
                ))}
            </div>
          ))}         

      </List>

      <div className={classes.orderSummary}>
        <TextField
          className={classes.orderNote}
          label="Order Note"
          variant="outlined"
          multiline
          rows="3"
          rowsMax="5"
          fullWidth
          value={orderNote}
          onChange={handleOrderNoteChange}
        />
        <Button
          className={classes.submitButton}
          color="primary"
          variant="contained"
          fullWidth
          onClick={handleSave}
        >
          Complete Order
        </Button>
      </div>
    </PaperContainer>
  );
}
