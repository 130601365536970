import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Typography, TextField, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import AppContext, { AppConsumer } from 'app-context'

import SubmitButton from 'components/submit-button'
import PaperContainer from 'components/paper-container';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(2),
    height: 80,
    width: 80
  },
  form: {
    width: '100%', // Fix IE 11 issue
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

function Login(props) {
  const { user } = useContext(AppContext)
  const classes = useStyles()

  const [values, setValues] = useState({
    username: '',
    password: ''
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  function handleChange(name) {
    return function (event) {
      setValues({
        ...values,
        [name]: event.target.value
      })
    }
  }

  function redirect(props) {
    const from = props.location.state && props.location.state.from
    const redirectUrl = from ? from.pathname + from.search + from.hash : '/'
    props.history.push(redirectUrl)
  }

  async function handleSubmit(event, login) {
    event.preventDefault()

    setIsSubmitting(true)
    const loggedIn = await login(values)
    setIsSubmitting(false)

    if (loggedIn) {
      redirect(props)
    }
  }

  useEffect(() => {
    if (user) {
      return redirect(props)
    }

    return () => {
      //cleanup
    }
  }, [props, user])

  return (
    <AppConsumer>
      {({login}) => (
        <PaperContainer>
          <Container maxWidth="xs" className={classes.root}>
            <Avatar src="/images/hava-java-logo.png" alt="Hava Java" className={classes.avatar} />
            <Typography component="h1" variant="h5">Sign In</Typography>

            <form className={classes.form} onSubmit={e => handleSubmit(e, login)}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={values.username}
                onChange={handleChange('username')}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                type="password"
                name="password"
                autoComplete="current-password"
                value={values.password}
                onChange={handleChange('password')}
              />

              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember Me"
              /> */}

              <SubmitButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                isSubmitting={isSubmitting}
              >Submit</SubmitButton>

            </form>
          </Container>
        </PaperContainer>
      )}
    </AppConsumer>
  )
}

export default withRouter(Login)
